<template>
    <div class="banner-page" v-bind:style="{'background-image': 'url(' + require('../assets/images/banner-page.png') + ')'}">
        <div class="container">
            <div class="col-12 py-5">
                <p class="subtitle text-white">{{ subtitle }}</p>
                <h2 class="title text-white">{{ title }}</h2>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'HeaderTitle',
        props: ['title', 'subtitle']
    }
</script>
