<template>
    <div id="contact" class="frontpage">
        <HeaderTitle :title="'Jangan ragu menghubungi Kami.'" :subtitle="'Hubungi Kami'" />
        <section>
            <div class="container py-5">
                <div class="col-12">
                    <div class="row py-5">
                        <div class="col-12 col-md-5 mb-5">
                            <h2 class="title text-black mb-3">Hubungi Kami</h2>
                            <p>Jika Anda memiliki pertanyaan lebih lanjut, jangan ragu untuk menghubungi kami: </p>
                            <table class="mt-4">
                                <tr>
                                    <td>
                                        <div class="icon-box"><Icon icon="bx:bxs-phone" /></div>
                                    </td>
                                    <td>
                                        <p>Nomor Telepon</p>
                                        <h2 class="title text-black mt-1">(031) 2981005</h2>
                                    </td>
                                </tr>
                            </table>
                            <table class="mt-5">
                                <tr>
                                    <td>
                                        <div class="icon-box"><Icon icon="fluent:mail-28-filled" /></div>
                                    </td>
                                    <td>
                                        <p>Email</p>
                                        <h2 class="title text-black mt-1"><a href="mailto:contact@infinity.ubayasm.com">contact@infinity.ubayasm.com</a></h2>
                                    </td>
                                </tr>
                            </table>
                            <table class="mt-5">
                                <tr>
                                    <td>
                                        <div class="icon-box"><Icon icon="fontisto:map-marker-alt" /></div>
                                    </td>
                                    <td>
                                        <p>Map Street</p>
                                        <h2 class="title text-black mt-1">Raya Kalirungkut, Kali Rungkut, Kec. Rungkut, Kota SBY, Jawa Timur 60293</h2>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-12 col-md-7">
                            <h2 class="title text-black mb-5">Kirim Pesan Kepada Kami</h2>
                            <form action="#">
                                <div class="form-group">
                                    <label for="name">Nama</label>
                                    <input type="text" class="form-control" placeholder="Silakan masukkan nama Anda" required>
                                </div>
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="email" class="form-control" placeholder="Silakan masukkan email Anda" required>
                                </div>
                                <div class="form-group">
                                    <label for="subject">Judul</label>
                                    <input type="text" class="form-control" placeholder="Silakan masukkan judul pesan Anda" required>
                                </div>
                                <div class="form-group">
                                    <label for="message">Pesan</label>
                                    <textarea class="form-control" cols="30" rows="10" placeholder="Silakan masukkan pesan Anda" required></textarea>
                                </div>
                                <button type="submit" class="btn btn-primary">Kirim Pesan</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'
    import HeaderTitle from '@/components/HeaderTitle'

    export default {
        name: 'ContactUs',
        components: {
            Icon,
            HeaderTitle
        },
        data () {
            return {}
        }
    }
</script>
